/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*background-color: #222; *//* Dark background color */
  background-color: rgb(33, 37, 41);
  color: white; /* Set text color to white for better contrast */
}

body {
  font-family: 'Open Sans', sans-serif;
}

/* Header Styles */
header {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  z-index: 10; /* Ensure the header is above other content */
  padding: 10px 0;
}

header nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin: 0;
}

header nav ul li {
  display: inline;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}
.logo-img {
  width: 100px;
  height: auto;
}
/* Hero Section */
.hero {
  background-image: url('/public/hero-1.jpg'); /* Add a suitable background image */
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.hero-content {
  max-width: 600px;
  margin: 0 auto;
  z-index: 1; /* Ensure content is above the hero image */
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay to improve text readability */
  z-index: 0;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.hero-buttons .btn {
  margin: 0 10px;
  position: relative;
  z-index: 1;
}

/* Section Styles */
section {
  padding: 60px 20px;
  text-align: center;
}

section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

section p {
  font-size: 1.25rem;
}

/* Footer Styles */
footer ul {
  list-style: none;
  padding: 0;
  gap: 1rem;
  text-align: center;
}

footer p {
  margin-top: 1rem;
}

/* Form Styles */
form label {
  display: block;
  margin-bottom: 0.5rem;
}

form input, form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

form button {
  padding: 0.5rem 1rem;
}

/* Team Styles */
.team-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.team-member {
  flex: 1 1 calc(33% - 1rem);
  text-align: center;
}

/* Main Padding */
main {
  padding: 1rem;
}

/* Buttons */
.btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-outline-light {
  border: 2px solid white;
  color: white;
}

.btn:hover {
  opacity: 0.8;
}

.copyright {
  text-align: center;
  padding: 1rem;
  color: white;
}