/* CareersPage.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
   
  p.lead {
    font-size: 1.125rem;
    text-align: left;
  }
  p {
    font-size: 1rem;
    text-align: left;
  }
  h2 {
    text-align: left;
  }
  section {
    margin-bottom: 20px !important; /* Further reduced margin */
    padding-bottom: 20px !important;
    padding-top: 10px !important;
  }
  
  .list-group-item {
    padding: 0.5rem 1rem; /* Keep the reduced padding */
    margin-bottom: 0.25rem; /* Keep the reduced margin */
    background-color: #f8f9fa;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  @media (min-width: 768px) {
    .list-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.25rem; /* Further reduced gap */
    }
  
    .list-group-flush .list-group-item {
      border-left: 0;
      border-right: 0;
      border-radius: 0;
    }
  }
  
  @media (min-width: 1024px) {
    h1 {
      font-size: 3rem;
    }
  
    h2 {
      font-size: 2.25rem;
    }
  }