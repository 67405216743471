/* Hero Section */
/* 
.hero {
    background-image: url('/public/hero-1.jpg'); 
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 100px 20px;
  }
  
  .hero-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
  
  .hero-buttons .btn {
    margin: 0 10px;
  } */
  
  /* General Styling */
  body {
    font-family: 'Open Sans', sans-serif;
  }
  
  section {
    padding: 60px 20px;
    text-align: center;
  }
  
  section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  section p {
    font-size: 1.25rem;
  }
  
  /* Buttons */
  .btn {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
  }
  
  .btn-outline-light {
    border: 2px solid white;
    color: white;
  }
  
  .btn:hover {
    opacity: 0.8;
  }
  html {
    scroll-behavior: smooth;
  }